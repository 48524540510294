import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Catalog from './pages/Catalog';
import Detail from './pages/Detail';
import Contact from './pages/Contact';
import MainLayout from './layouts/MainLayout';
import { useTranslation } from 'react-i18next';
import NotFoundPage from './components/NotFoundPage';

function App() {
  const { t } = useTranslation();
  
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Update state whenever the window is resized
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout isDetail={false} title={t('header.title1')} image={ windowSize.width <= 768 ? 'home_mobile2.png' : 'home.png'} isAloneButton={false} />} >
          <Route path="" element={<Home width={windowSize.width}/>} />
        </Route>
        {/* <Route path="/" element={<MainLayout isDetail={false} title={t('header.title2')} image='catalog.png' isAloneButton={true} />} >
          <Route path="catalog" element={<Catalog />} />
        </Route>
        <Route path="/" element={<MainLayout isDetail={true} title='' image='' isAloneButton={true} />} >
          <Route path="detail/:id" element={<Detail />} />
        </Route> */}
        <Route path="/" element={<MainLayout isDetail={true} title='' image='' isAloneButton={true} />} >
          <Route path="contact" element={<Contact width={windowSize.width}/>} />
        </Route>
        <Route path="*" element={<NotFoundPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
