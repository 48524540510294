import React from 'react'
import "../styles/header.css";
import GoldButton from './UI/GoldButton';
import BorderButton from './UI/BorderButton';
import LanguageSelector from './UI/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
    title: string;
    image: string;
    isAloneButton: boolean;
}

function Header(props: Props) {
    const { image, isAloneButton, title } = props;
    const { t } = useTranslation();

    return (
        <header className='header'>
            <div className='header_content'>
                <div className='header_left_box'>
                    <div className='header_left_box_body'>
                        <Link to="/"><img className='logo' src={process.env.PUBLIC_URL + 'logo.png'} alt="" /></Link>
                        <div className='header_sub_content'>
                            <div>
                                <h1 className='header_title'>
                                    {title}
                                </h1>
                                {
                                    isAloneButton ?
                                        <></>
                                        :
                                        <p className='header_sub_title'>
                                            <span></span>
                                            <p>{t('header.sub_title')}</p>
                                        </p>
                                }

                            </div>
                            {/* {
                                isAloneButton ?
                                    <div className='header_buttons'>
                                        <div><GoldButton to='/contact' title={t('header.button_contact')} /></div>
                                    </div>
                                    :
                                    <div className='header_buttons'>
                                        <div><GoldButton to='/catalog' title={t('header.button_catalog')} /></div>
                                        <div><BorderButton to='/contact' title={t('header.button_contact')} /></div>
                                    </div>

                            } */}
                        </div>
                    </div>
                </div>
                <div className='header_right_box'>
                    <img className='header_mid_img' src={process.env.PUBLIC_URL + '/halal.png'} alt="" />
                    <img className='right_box_img' src={process.env.PUBLIC_URL + image} alt="" />

                </div>
                <div className='header_right_box_lang'>
                    <LanguageSelector />
                </div>
            </div>
        </header>
    )
}

export default Header
