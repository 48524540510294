import React from 'react'
import { useTranslation } from 'react-i18next';
import { FaInstagram } from 'react-icons/fa'
import { Link } from 'react-router-dom';

interface Props {
    id: number;
    image: string;
}

interface Title {
    title: string;
    sub_title: string;
}

function InstaImageItem(props: Props) {
    const { id, image } = props;
    const { t } = useTranslation();
    const socials = t('insta_image_title', { returnObjects: true }) as Title[]

    return (
        <div className="image">
            <Link to="https://www.instagram.com/pavelfood?igsh=MWZvNjZneXJ4OHdrZg==">
                <img src={process.env.PUBLIC_URL + '/img/' + image} alt="" />
                <div className='image_title'>
                    <div>

                        {
                            socials.length > id && socials[id]
                                ?
                                <>
                                    <img src={process.env.PUBLIC_URL + 'king.png'} alt="" />
                                    <h3>{socials[id].title}</h3>
                                    <p>{socials[id].sub_title}</p>
                                </>
                                :
                                <>
                                    <h3></h3>
                                    <p></p>
                                </>
                        }
                    </div>
                </div>
                <div className="image_overlay">
                    <div className="image_text"><FaInstagram /></div>
                </div>
            </Link>
        </div>
    )
}

export default InstaImageItem
