// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Full-screen container for the 404 page */
.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    text-align: center;
}

/* Main content of the 404 page */
.not-found-content {
    max-width: 500px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Large 404 title */
.not-found-title {
    font-size: 6rem;
    color: #ff4c4c;
    margin-bottom: 20px;
}

/* Text message */
.not-found-text {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 30px;
}

/* Button to navigate back home */
.not-found-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Button hover effect */
.not-found-button:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/styles/notFound.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA,iCAAiC;AACjC;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,yCAAyC;AAC7C;;AAEA,oBAAoB;AACpB;IACI,eAAe;IACf,cAAc;IACd,mBAAmB;AACvB;;AAEA,iBAAiB;AACjB;IACI,iBAAiB;IACjB,WAAW;IACX,mBAAmB;AACvB;;AAEA,iCAAiC;AACjC;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;AAC1C;;AAEA,wBAAwB;AACxB;IACI,yBAAyB;AAC7B","sourcesContent":["/* Full-screen container for the 404 page */\n.not-found-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f9f9f9;\n    text-align: center;\n}\n\n/* Main content of the 404 page */\n.not-found-content {\n    max-width: 500px;\n    padding: 20px;\n    border-radius: 10px;\n    background-color: #fff;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n/* Large 404 title */\n.not-found-title {\n    font-size: 6rem;\n    color: #ff4c4c;\n    margin-bottom: 20px;\n}\n\n/* Text message */\n.not-found-text {\n    font-size: 1.5rem;\n    color: #333;\n    margin-bottom: 30px;\n}\n\n/* Button to navigate back home */\n.not-found-button {\n    padding: 10px 20px;\n    font-size: 1rem;\n    color: #fff;\n    background-color: #007bff;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\n/* Button hover effect */\n.not-found-button:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
