// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_layout{
    width: 100%;
}

.main_layout_body{
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    height: auto;
    margin: 0 auto;
}

@media (max-width: 908px) {
    .main_layout_body{
        display: flex;
        flex-direction: column;
        max-width: 100%;
        /* min-width: 700px; */
        height: auto;
        /* margin: 0 auto; */
        margin: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/mainlayout.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,eAAe;QACf,sBAAsB;QACtB,YAAY;QACZ,oBAAoB;QACpB,SAAS;IACb;AACJ","sourcesContent":[".main_layout{\n    width: 100%;\n}\n\n.main_layout_body{\n    display: flex;\n    flex-direction: column;\n    max-width: 1440px;\n    height: auto;\n    margin: 0 auto;\n}\n\n@media (max-width: 908px) {\n    .main_layout_body{\n        display: flex;\n        flex-direction: column;\n        max-width: 100%;\n        /* min-width: 700px; */\n        height: auto;\n        /* margin: 0 auto; */\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
